import React from 'react'
import Form from '../components/Form'

export default function FormPage() {
  return (
    <>
      <style dangerouslySetInnerHTML={{ __html: `
        body { background-color: transparent !important; }
      ` }} />
      <Form />
      <div className="h-10" />
    </>
  )
}
